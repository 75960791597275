document.addEventListener('DOMContentLoaded', function() {
    const footer = document.getElementsByTagName('footer')[0]; // ID verwenden
    if (footer) {
        const footerContainer = document.createElement('div');
        footerContainer.id = 'new-footer-container';
        footerContainer.appendChild(footer);
        document.body.appendChild(footerContainer);
    } else {
        console.error('Footer-Element mit ID #template-footer nicht gefunden!');
    }
});

window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});


//Bild auomatisch einsetzen

window.addEventListener('load', function() {

    //Arbeitsort
    // Arbeitsort - Bild 1
    var bildContainer1 = document.getElementById('text-image-content-block-id-8209034c');
    var bild1 = bildContainer1.querySelector('img');
    var hintergrundElement1 = document.getElementById('Arbeitsort1');
    var bildPfad1 = bild1.src;
    hintergrundElement1.style.backgroundImage = 'url(' + bildPfad1 + ')';

    // Arbeitsort - Bild 2
    var bildContainer2 = document.getElementById('text-image-content-block-id-c0a0d21d');
    var bild2 = bildContainer2.querySelector('img');
    var hintergrundElement2 = document.getElementById('Arbeitsort2');
    var bildPfad2 = bild2.src;
    hintergrundElement2.style.backgroundImage = 'url(' + bildPfad2 + ')';

    // Arbeitsort - Bild 3
    var bildContainer3 = document.getElementById('text-image-content-block-id-e15a315b');
    var bild3 = bildContainer3.querySelector('img');
    var hintergrundElement3 = document.getElementById('Arbeitsort3');
    var bildPfad3 = bild3.src;
    hintergrundElement3.style.backgroundImage = 'url(' + bildPfad3 + ')';

    // Arbeitsort - Bild 4
    var bildContainer4 = document.getElementById('text-image-content-block-text-image-content-ab944400');
    var bild4 = bildContainer4.querySelector('img');
    var hintergrundElement4 = document.getElementById('Arbeitsort4');
    var bildPfad4 = bild4.src;
    hintergrundElement4.style.backgroundImage = 'url(' + bildPfad4 + ')';

    // Arbeitsort - Bild 5
    var bildContainer5 = document.getElementById('text-image-content-block-id-e647d6ff');
    var bild5 = bildContainer5.querySelector('img');
    var hintergrundElement5 = document.getElementById('Arbeitsort5');
    var bildPfad5 = bild5.src;
    hintergrundElement5.style.backgroundImage = 'url(' + bildPfad5 + ')';

    // Arbeitsort - Bild 6
    var bildContainer6 = document.getElementById('text-image-content-block-id-3478c1c7');
    var bild6 = bildContainer6.querySelector('img');
    var hintergrundElement6 = document.getElementById('Arbeitsort6');
    var bildPfad6 = bild6.src;
    hintergrundElement6.style.backgroundImage = 'url(' + bildPfad6 + ')';

    // Arbeitsort - Bild 7
    var bildContainer7 = document.getElementById('text-image-content-block-id-ff78f48f');
    var bild7 = bildContainer7.querySelector('img');
    var hintergrundElement7 = document.getElementById('Arbeitsort7');
    var bildPfad7 = bild7.src;
    hintergrundElement7.style.backgroundImage = 'url(' + bildPfad7 + ')';

    // Arbeitsort - Bild 8
    var bildContainer8 = document.getElementById('text-image-content-block-id-4027afd5');
    var bild8 = bildContainer8.querySelector('img');
    var hintergrundElement8 = document.getElementById('Arbeitsort8');
    var bildPfad8 = bild8.src;
    hintergrundElement8.style.backgroundImage = 'url(' + bildPfad8 + ')';


    //Zitate

    // Zitat - Bild 1
    var zitatbildContainer1 = document.getElementById('text-image-content-block-id-c8dbddf5');
    var zitatbild1 = zitatbildContainer1.querySelector('img');
    var hintergrundElement1 = document.getElementById('Person1');
    var zitatbildPfad1 = zitatbild1.src;
    hintergrundElement1.style.backgroundImage = 'url(' + zitatbildPfad1 + ')';

    // Zitat - Bild 2
    var zitatbildContainer2 = document.getElementById('text-image-content-block-id-09361026'); // Ändere 'xxxxxxxx' entsprechend der ID des zweiten Bild-Containers
    var zitatbild2 = zitatbildContainer2.querySelector('img');
    var hintergrundElement2 = document.getElementById('Person2'); // Ändere 'Person2' entsprechend der ID des Hintergrundelements für das zweite Bild
    var zitatbildPfad2 = zitatbild2.src;
    hintergrundElement2.style.backgroundImage = 'url(' + zitatbildPfad2 + ')';

    // Zitat - Bild 3
    var zitatbildContainer3 = document.getElementById('text-image-content-block-id-b37ade3d'); // Ändere 'xxxxxxxx' entsprechend der ID des dritten Bild-Containers
    var zitatbild3 = zitatbildContainer3.querySelector('img');
    var hintergrundElement3 = document.getElementById('Person3'); // Ändere 'Person3' entsprechend der ID des Hintergrundelements für das dritte Bild
    var zitatbildPfad3 = zitatbild3.src;
    hintergrundElement3.style.backgroundImage = 'url(' + zitatbildPfad3 + ')';

    // Zitat - Bild 4
    var zitatbildContainer4 = document.getElementById('text-image-content-block-id-ebead934'); // Ändere 'xxxxxxxx' entsprechend der ID des vierten Bild-Containers
    var zitatbild4 = zitatbildContainer4.querySelector('img');
    var hintergrundElement4 = document.getElementById('Person4'); // Ändere 'Person4' entsprechend der ID des Hintergrundelements für das vierte Bild
    var zitatbildPfad4 = zitatbild4.src;
    hintergrundElement4.style.backgroundImage = 'url(' + zitatbildPfad4 + ')';

    // Zitat - Bild 5
    var zitatbildContainer5 = document.getElementById('text-image-content-block-id-f368bce7'); // Ändere 'xxxxxxxx' entsprechend der ID des fünften Bild-Containers
    var zitatbild5 = zitatbildContainer5.querySelector('img');
    var hintergrundElement5 = document.getElementById('Person5'); // Ändere 'Person5' entsprechend der ID des Hintergrundelements für das fünfte Bild
    var zitatbildPfad5 = zitatbild5.src;
    hintergrundElement5.style.backgroundImage = 'url(' + zitatbildPfad5 + ')';
    
    // Zitat - Bild 6
    var zitatbildContainer6 = document.getElementById('text-image-content-block-id-cf9f58da'); // Ändere 'xxxxxxxx' entsprechend der ID des fünften Bild-Containers
    var zitatbild6 = zitatbildContainer6.querySelector('img');
    var hintergrundElement6 = document.getElementById('Person6'); // Ändere 'Person6' entsprechend der ID des Hintergrundelements für das fünfte Bild
    var zitatbildPfad6 = zitatbild6.src;
    hintergrundElement6.style.backgroundImage = 'url(' + zitatbildPfad6 + ')';

});



//URL zum kopieren//



document.addEventListener("DOMContentLoaded", function() {
    document.getElementById("text-block-rich-text-a0e7f9b1").addEventListener("click", function() {
        // Text, der in die Zwischenablage kopiert werden soll
        var textToCopy = this.textContent;

        // Erstelle ein unsichtbares Textfeld, um den Text zum Kopieren einzufügen
        var textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed"; // Stelle sicher, dass das Textfeld sichtbar ist, auch wenn es außerhalb des sichtbaren Bereichs liegt
        document.body.appendChild(textArea);

        // Selektiere den Text im Textfeld
        textArea.select();

        // Versuche, den Text zu kopieren
        try {
            var successful = document.execCommand('copy');
            var message = successful ? 'Text wurde kopiert': 'Kopieren fehlgeschlagen';
            console.log(message);
        } catch (err) {
            console.error('Kopieren fehlgeschlagen: ', err);
        }

        // Entferne das Textfeld aus dem DOM, da es nicht mehr benötigt wird
        document.body.removeChild(textArea);
    });
});

document.addEventListener("DOMContentLoaded", function() {
    var currentPageURL = window.location.href;

    // Finde den Index des Hashtags (#)
    var hashIndex = currentPageURL.indexOf("#");

    // Teile die URL in zwei Teile: vor und nach dem Hashtag
    var urlBeforeHash = currentPageURL.substring(0, hashIndex);
    var urlAfterHash = currentPageURL.substring(hashIndex);

    // Suche nach dem Index von www in der URL
    var wwwIndex = urlBeforeHash.indexOf("www");

    // Extrahiere den Domainnamen (ab www bis zum Hashtag)
    var domainName = urlBeforeHash.substring(wwwIndex);

    // Kombiniere den Domainnamen und den Hashtag
    var displayedURL = domainName + urlAfterHash;

    // Aktualisiere den Textinhalt des Elements
    var elementToUpdateContainer = document.getElementById("text-block-rich-text-a0e7f9b1");
    var elementToUpdate = elementToUpdateContainer.querySelector('p');
    elementToUpdate.textContent = displayedURL;
});